<template>
  <div class="w-full h-full">
    <JoszakiToastContainer />
    <JoszakiAlert />
    <JoszakiConfirm />
    <Nuxt />
  </div>
</template>

<script>
import { defineComponent } from "@nuxtjs/composition-api";
import JoszakiAlert from "~/components/joszaki/JoszakiAlert.vue";
import JoszakiToastContainer from "~/components/_refactored/common/JoszakiToastContainer.vue";
import JoszakiConfirm from "~/components/joszaki/JoszakiConfirm.vue";

export default defineComponent({
  components: {
    JoszakiToastContainer,
    JoszakiAlert,
    JoszakiConfirm,
  },
});
</script>
